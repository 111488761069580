<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
  <q-resize-observer @resize="onResize" />
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onResize() {
      this.$store.dispatch("actSetClientSize", {
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
  },
};
</script>