import { createStore } from "vuex";

import { actions } from '@/store/actions'
import { state } from '@/store/states'
import { getters } from '@/store/getters'
import { mutations } from '@/store/mutations'
///////////////////////////////////业务模块////////////////////////////////////////////
import { appSetting } from '@/store/modules/app/appsettings'
import { appUser } from '@/store/modules/app/appUser'

import { SysHosp } from '@/store/modules/sys/sysHosp'
import { SysHospUser } from '@/store/modules/sys/sysHospUser'
import { SysUser } from '@/store/modules/sys/sysUser'
import { SysRole } from '@/store/modules/sys/sysRole'

import { SysRoleUser } from '@/store/modules/sys/sysRoleUser'
import { SysRoleModules } from '@/store/modules/sys/sysRoleModules'

import { SysLog } from '@/store/modules/sys/sysLog'
import { MultiPageBar } from "@/store/modules/app/multiPageBar";

export const store = createStore({
    modules:     
    {
        appSetting,
        appUser,
        SysHosp,
        SysUser,
        SysRole,
        SysRoleUser,
        SysRoleModules,
        SysHospUser,
        SysLog,
        MultiPageBar
    },
    state,
    getters,
    actions,
    mutations
});
