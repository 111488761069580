<template>
  <q-layout view="hHh lpR fFf" style="background-color: #f3f3f7;">
    <div>
      <div style="padding-top:63px;padding-left:73px;padding-right:73px;height:100%;width:100%">
        <!-- <div class="q-my-lg hospName">{{currentHosp.HospName}}</div> -->
        <div class="row">
          <q-card class="col-2 leftCard">
            <q-card-section class="flex flex-center q-pb-sm" style="margin-top:81px">
              <q-avatar icon="fas fa-user" size="80px" color='grey-13' text-color="white" @click="toggleRightDrawer" />
            </q-card-section>
            <q-card-section align="center">
              <div class="userName">您好，{{ userName }}</div>
              <div class="loginTime">登录日期：{{ loginTime() }}</div>
            </q-card-section>
            <q-card-section class="flex flex-center">
              <q-btn flat round class="changeUserBtn" @click="actUserLogout(), $router.replace({ name: 'login' })">切换账号
              </q-btn>
            </q-card-section>
            <q-card-section class="flex flex-center ">
              <div class="statisticPanel">
                <div class="row"></div>
                <div class="row"></div>
                <div class="row"></div>
              </div>
            </q-card-section>
          </q-card>
          <q-card flat class="col-10" style="border-radius:0px 20px 20px 0px;">
            <q-card-section title>
              <div class="row">
                <span class="projTitle">
                  <q-icon class="q-mx-md" size="20px" name="fas fa-home" />
                  {{ title }}
                </span>
                <q-space />

                <div class="sysVer q-px-md">{{ appSettings.AppVerison }}</div>
                <q-btn size="sm" class="projTitleBtn">更新日志</q-btn>
              </div>
            </q-card-section>
            <q-separator class="q-mx-md" />
            <div class="row q-col-gutter-md q-pa-xl q-mx-sm">
              <div class="col-3" v-for="d in authModules" :key="d.ModuleID">
                <q-card style="min-height: 120px" class="q-mx-sm cardStyle">
                  <q-card-section horizontal class="q-my-md q-ml-lg">
                    <q-card-section class="col-3 flex flex-center">
                      <q-avatar :icon="d.Icon" size="60px"
                        :style="{ 'background-color': d.IconStyle ? d.IconStyle : '#027BE3' }" text-color="white" />
                    </q-card-section>

                    <q-card-section class="col-8">
                      <q-item-label class="q-mt-md q-mb-xs cardTitle ellipsis"><a :href="d.RoutePath">{{
                          d.ModelName
                      }}</a></q-item-label>
                    </q-card-section>
                  </q-card-section>
                  <q-separator class="q-mx-lg" />
                  <q-card-actions>
                    <span class="cardSubtitle q-ml-lg q-mt-sm ellipsis">{{ d.ModelDesc }}</span>
                  </q-card-actions>
                </q-card>
              </div>
            </div>
          </q-card>
        </div>
      </div>
    </div>
    <!-- 右侧抽屉功能 -->
    <q-drawer show-if-above v-model="rightDrawerOpen" side="right" overlay bordered behavior="mobile">
      <DrawerUserInfo />
    </q-drawer>

    <q-toolbar style="color:#454545">
      <q-space />
      <div>
        技术支持：青岛万斯迈技术有限公司
      </div>
      <q-separator vertical class="q-ma-sm" />
      <div>
        <q-icon name="info" color="info"/>
        <a href="#">联系我们</a>
      </div>
      <q-space />
    </q-toolbar>
  </q-layout>
</template>

<script>
// import ToolbarRight from "@/views/Layout/basic/header/toolbarRight";
import DrawerUserInfo from "@/views/Layout/basic/rightDrawer/sysUserInfo";

import { mapActions, mapGetters } from "vuex";
import { useMeta } from "quasar";
import moment from "moment";

export default {
  components: {
    // ToolbarRight,
    DrawerUserInfo,
  },
  computed: {
    ...mapGetters(["appSettings"]),
    ...mapGetters("appUser", ["authModules", "currentHosp", "authHosps", "userName", "currentUser"]),
  },
  data() {
    return {
      rightDrawerOpen: false,
      title: "智慧医疗管理平台"
    };
  },
  created() {
    this.actLoadSetting();

    const metaData = {
      // sets document title
      title: this.title,
      // optional; sets final title as "Index Page - My Website", useful for multiple level meta
      titleTemplate: (title) => `${title} `,
    };
    useMeta(metaData);
  },
  methods: {
    ...mapActions("appSetting", ["actLoadSetting"]),
    ...mapActions("appUser", [
      "actUserLogout",
    ]),
    toggleRightDrawer() {
      console.log(this.rightDrawerOpen)
      this.rightDrawerOpen = !this.rightDrawerOpen;
    },
    loginTime() {
      return moment().format("YYYY年MM月DD日")
    },

  },
};
</script>

<style scoped>
a:link {
  /* 去除a 标签默认的下划线 */
  text-decoration: none;
  color: #454545
}

a:visited {
  /* 更改文字属性 */
  color: #454545
}

a:visited {
  /* 更改文字属性 */
  color: #454545
}

.leftCard {
  min-height: 700px;
  background: linear-gradient(-86deg, #47ABFF, #2C76FF);
  border-radius: 20px 0px 0px 20px;
}

.cardStyle {
  height: 180px;
  background: #FFFFFF;
  border: 1px solid #E1E1E7;
  box-shadow: 0px 4px 8px 0px rgba(216, 216, 230, 0.45);
  border-radius: 18px;
}

.cardSubtitle {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #C1C1C1;
  line-height: 18px;
}

.cardTitle {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #454545;
  line-height: 30px;
}

.projTitle {
  font-size: 16px;
  font-weight: 400;
  color: #888888;
}

.userName {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 36px;
}

.loginTime {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  line-height: 32px;
}

.changeUserBtn {
  width: 122px;
  height: 33px;
  background: #84B9FF;
  border: 1px solid #AFD1FF;
  border-radius: 17px;
}

.statisticPanel {
  width: 263px;
  height: 329px;
  background: rgba(117, 183, 250, 0.6);
  border-radius: 20px;
}

.hospName {
  height: 28px;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #555555;
}

.sysVer {
  background: #F8F8F8;
  border-radius: 17px;
  color: #999999;
  line-height: 25px;
  margin-right: 8px
}

.projTitleBtn {
  width: 101px;
  background: linear-gradient(-86deg, #47ABFF, #2C76FF);
  border-radius: 17px;
  color: white;
  margin-right: 8px
}
</style>