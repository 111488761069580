
import { Encrypt, Decrypt } from "@/utils/encrypt";
import { axios } from '@/utils/request.js'
import msg from "@/config/notify";
import { store } from './store'
import router from './router'

//请求应答是否加密
const isSigned = false;
// 发起请求之前的拦截器nn
axios.interceptors.request.use(
    (config) => {
        // 如果有token 就携带tokon
        const token = store.getters["appUser/token"];
        if (token) {
            config.headers.Authorization = "Bearer " + token;
        }
        // 追加统一请求参数
        try {
            if (!config.data) config.data = {};
            const log = {
                LogUser: store.getters["appUser/userID"],
                LogHosp: store.getters["appUser/hospID"],
                AppVersion: store.getters.appSettings.AppVerison,
                OSName: store.getters.appSettings.ClientOS,
                BrowserName: store.getters.appSettings.ClientBrowser,
            };
            Object.assign(config.data, log);
            const data = JSON.stringify(config.data);
            config.data = { Sign: isSigned ? Encrypt(data) : data };
        } catch (ex) {
            msg.error(ex);
        }
        return config;
    },
    (error) => {
        msg.error(error.message);
        Promise.reject(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    (response) => {
        const res = response.data;
        if (response.status !== 200) {
            // msg.error(res.Message || "应答错误")
            return Promise.reject(new Error(res.Message || "应答错误"));
        } else {
            var data = isSigned
                ? JSON.parse(Decrypt(res.ReturnSign))
                : res.ReturnSign;
            return data;
        }
    },
    (error) => {
        let msg = "";

        if (error.response) {
            if (error.response.status === 401) {
                store.dispatch("appUser/actUserLogout").then(() => {
                    setTimeout(() => {
                        router.push({ path: "/login" }); //并在这里做页面登出操作
                    }, 1000);
                });
                return Promise.resolve({ Code: -1, Message: "授权已过期，请重新登录" });
            }
            if (error.response.status === 403) {
                msg = "拒绝访问:" + error.message;
            }
        }
        if (error.message === "Network Error") {
            msg = "网络故障，无法连接到数据服务器";
        } else {
            msg = error.message;
        }

        const parm = {
            Code: -1,
            Message: msg,
        };
        return Promise.resolve(parm);
    }
);