import { createApp } from 'vue'

import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from '@/quasar-user-options'
import router from './router'
import { store } from './store'
import { useVXETable } from '@/config/vxetable'
import { VueAxios } from '@/utils/request'

import msg from '@/config/notify'
import dlg from '@/config/dialog'
import "./interceptor"
localStorage.setItem("SYSTEM_KEY",0)

const app = createApp(App)
//UI
app.use(Quasar, quasarUserOptions)
//路由
app.use(router)
//vuex
app.use(store)
//vxeTable
app.use(useVXETable)
//axios
app.use(VueAxios)

//全局变量及方法
app.config.globalProperties.$store = store;
app.config.globalProperties.msg = msg
app.config.globalProperties.dlg = dlg

app.config.globalProperties.SystemKey = 0

//挂载
app.mount('#app')
