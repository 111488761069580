import { createRouter, createWebHashHistory, } from "vue-router";
import { store } from '../store'
import BlankView from '@/views/Layout/basic/BlankView.vue'
import WorkSpace from '@/views/Layout/basic/WorkSpace.vue'
import about from '@/views/Layout/About.vue'
import login from '@/views/Layout/Login.vue'
import p404 from '@/views/Layout/404.vue'

import LoadingBar from 'quasar/src/plugins/LoadingBar.js';

var sysKey = localStorage.getItem("SYSTEM_KEY") ? localStorage.getItem("SYSTEM_KEY") : 0

const getAsyncRoutes = (routes) => {
    const res = []
    if (!routes) return res
    routes.forEach(item => {
        if ((item.SysID == sysKey || sysKey == 0)) {
            const newItem = {}
            if (item.RouteComponent) {
                newItem.component = () => import(`@/views${item.RouteComponent}.vue`)
            }
            newItem.path = item.RoutePath
            newItem.name = item.RouteName
            newItem.redirect = item.RouteRedirect
            if (item.IsHide) newItem.hidden = true

            if (Object.prototype.hasOwnProperty.call(item, "SysName")) {
                newItem.meta = {
                    title: item.SysName,
                    icon: item.Icon,
                }
            }
            else {
                newItem.meta = {
                    title: item.ModelName,
                    icon: item.RouteIcon,
                }
            }
            if (item.Children && item.Children.length) {
                newItem.children = getAsyncRoutes(item.Children)
            }
            res.push(newItem)
        }
    })
    return res
}
//基础路由
const basicRoutes = [
    {
        path: "/",
        name: "root",
        redirect: "/home",
        component: BlankView,
        children: [
            {
                path: '/home',
                name: 'home',
                component: WorkSpace,
            }
        ],
    },
    {
        path: '/user',
        name: 'user',
        redirect: '/login',
        component: BlankView,
        children: [
            {
                path: '/login',
                name: 'login',
                component: login
            }
        ]
    },
    {
        path: "/about",
        name: 'about',
        component: about
    },
    {
        path: "/404",
        name: '404',
        component: p404
    },

    // {
    //     path: "/:catchAll(.*)", redirect: '404', hidden: true
    // }
]

const whiteList = []
const whiteRoutes = [
]

const getRoutes = () => {
    return [...getAsyncRoutes(store.getters['appUser/authModules'])]
}

const allRouter = basicRoutes.concat(whiteRoutes).concat(getRoutes())
const router = createRouter({
    history: createWebHashHistory(),
    routes: allRouter
});
store.dispatch('MultiPageBar/actClearPages')

router.beforeEach((to, from, next) => {
    if (store.getters['appUser/token']) {
        if (to.path === '/login') {
            next('/home')
        } else {
            if (store.getters['appUser/authModules'].length > 0) {
                const redirect = decodeURIComponent(from.query.redirect || to.path)
                if (redirect == '/') {
                    if (from.name === undefined) next()
                    else next({ path: "/home" })
                } else {
                    if (to.path === redirect) {
                        next()
                    } else {
                        next({ path: "/" })
                    }
                }
            }
            else {
                //登陆后找不着路由跳404
                if (to.matched.length === 0) {
                    next('/404') // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
                }
                else {
                    next()
                }
            }
        }
        LoadingBar.stop()
    } else {
        if (whiteList.includes(to.path)) {
            next()
        } else {
            if (from.name === 'login' || to.name === 'login') {
                next()
            } else {
                next({ path: '/login', query: { redirect: '/home' } })
            }
        }
    }
    LoadingBar.stop()
})

router.afterEach((to, from) => {
    if (from.name == "login") {
        var rs = getRoutes()
        rs.forEach(v => {
            router.addRoute(v);
        })
    }
    LoadingBar.stop()
})

export default router;